import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const CheckMark = (props: IIconProps) => {
  const { width = 24, className, color = '#333' } = props;
  const height = width;

  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path stroke={color} strokeWidth="2" d="M5 12l4.983 5L19 7" strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default React.memo(CheckMark);
