import React from 'react';

import CalendarSquare from '../components/Icons/CalendarSquare';
import CheckCircle from '../components/Icons/CheckCircle';
import Checkmark from '../components/Icons/CheckmarkIcon';
import LinkIcon from '../components/Icons/Link';
import ShieldNoAccent from '../components/Icons/ShieldNoAccent';
import IconTextPair from '../components/IconTextPair/IconTextPair';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GraphqlImage } from '../utils/IPageProps';

import style from '../styles/pages/solutions.scss';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

interface Props {
  data: {
    groupPeople: GraphqlImage;
    ladyOnPhone: GraphqlImage;
  };
}

const SolutionsPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <SEO title="Solutions" />
      <section className={style.hero}>
        <div className="container">
          <LinkIcon color="#00B7B7" />
          <h2>
            We are a talent group that mixes experience, market knowledge and technology to drive
            traffic and consumers to your business!
          </h2>
        </div>
      </section>
      <div className={style.images}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-5">
              <Img
                className={style.groupPeople}
                fluid={data.groupPeople.childImageSharp.fluid}
                alt="Group of people"
              />
            </div>
            <div className="col-12 col-md-6 offset-md-1">
              <IconTextPair
                className={style.singleInView}
                icon={<ShieldNoAccent />}
                text="Secure your marketing strategy to set yourself up for massive success"
              />
              <IconTextPair
                className={style.singleInView}
                icon={<LinkIcon />}
                text="AI powered SEO solutions to connect you and your consumers seamlessly"
              />
              <IconTextPair
                className={style.singleInView}
                icon={<CalendarSquare />}
                text="Meet your due dates and success goals on time"
              />
              <IconTextPair
                className={style.singleInView}
                icon={<CheckCircle />}
                text="Lock into a full spectrum marketing strategy for maximum exposure"
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 offset-md-1 order-md-last">
              <Img
                className={style.ladyOnPhone}
                fluid={data.ladyOnPhone.childImageSharp.fluid}
                alt="Lady sitting on phone"
              />
            </div>
            <div className="col-12 col-md-5">
              <h2>So what services can you expect?</h2>
              <p>
                Made by a team of researchers, analysts, and visionaries dedicated to making your
                marketing fire on all cylinders through various marketing channels that work for you
                and your business:
              </p>
              <ul>
                <li>
                  <Checkmark />
                  SEO Optimizations.
                </li>
                <li>
                  <Checkmark />
                  Funnel Optimizations.
                </li>
                <li>
                  <Checkmark />
                  Roadmap and goal creation.
                </li>
                <li>
                  <Checkmark />
                  Social Media Marketing.
                </li>
                <li>
                  <Checkmark />
                  Pay per click Marketing.
                </li>
                <li>
                  <Checkmark />
                  Account Based Marketing.
                </li>
                <li>
                  <Checkmark />
                  Customer Funnel Optimization.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const indexQuery = graphql`
  {
    groupPeople: file(relativePath: { eq: "Solutions/solutions-img1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ladyOnPhone: file(relativePath: { eq: "Solutions/solutions-img2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SolutionsPage;
